import initialState from '../_helpers/initialState'
import {
  SET_LOADING,
  SET_TARGET_LOADING,
  SET_WINS,
  SET_TARGETS,
  ADD_WIN,
  UPDATE_WIN,
  DELETE_WIN,
  SET_ONLINE_WINS
} from './constants'
import { wins, targets } from './services'
import { castStringBooleans } from './utils'
import api from '@/services/api_v2'

export default {
  namespaced: true,
  state: {
    ...initialState,
    targets: {
      loading: false,
      winTargets: []
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_TARGET_LOADING](state, payload) {
      state.targets.loading = payload
    },
    [SET_WINS](state, payload) {
      state.data = payload
    },
    [SET_TARGETS](state, payload) {
      state.targets = payload
    },
    [SET_ONLINE_WINS](state, payload) {
      state.onlineWins.score = payload
    },
    [ADD_WIN](state, payload) {
      state.data.push(payload)
    },
    [UPDATE_WIN](state, payload) {
      // We don't use our updateList helper given the composite nature of the
      // wins primary keys (combination of partition key and sort key).
      const index = state.data.findIndex((d) => d.operator === payload.operator && d.timestamp === payload.timestamp)
      state.data.splice(index, 1, payload)
    },
    [DELETE_WIN](state, payload) {
      const index = state.data.findIndex((d) => d.operator === payload.operator && d.timestamp === payload.timestamp)
      state.data.splice(index, 1)
    }
  },
  actions: {
    fetch({ state, commit }, data) {
      commit(SET_LOADING, true)
      wins.index(data).then(async ({ data }) => {
        let enhancedData = []
        await Promise.all(
          castStringBooleans(data).map(async (win) => {
            if (win.companyId && win.companyId !== 'N/A') {
              try {
                const response = await api.getCompany(win.companyId)
                enhancedData.push({ ...win, companyName: response.data.legal_name })
              } catch {
                enhancedData.push({ ...win, companyName: null })
              }
            } else {
              enhancedData.push({ ...win, companyName: null })
            }
          })
        )
        commit(SET_WINS, enhancedData)
        commit(SET_LOADING, false)
      })
    },
    fetchTargets({ state, commit }) {
      commit(SET_TARGET_LOADING, true)
      targets.index().then(({ data }) => {
        commit(SET_TARGETS, data)
        commit(SET_TARGET_LOADING, false)
      })
    },
    async save({ state, commit }, { isNew, ...payload }) {
      const { success, message, data } = await (isNew ? wins.create(payload) : wins.update(payload))
      if (success) commit(isNew ? ADD_WIN : UPDATE_WIN, castStringBooleans(data))
      return { success, message }
    },
    async destroy({ commit }, params) {
      const { success, message } = await wins.destroy(params)
      if (success) commit(DELETE_WIN, params)
      return { success, message }
    }
  }
}
