export default {
  title: 'Comptabilité',
  irregularities: {
    duplicate_premium_due_notice: 'Quittance terme dupliquée',
    missing_splitting: 'Fractionnement manquant',
    missing_period: 'Période manquante',
    missing_net_premium: 'Prime nette manquante',
    missing_total_premium: 'Prime totale manquante',
    missing_commission: 'Commission manquante',
    mismatched_splitting_and_period: 'Incohérence entre fractionnement et période',
    overlapping_notice_periods: 'Chevauchement avec une autre quittance terme'
  }
}
