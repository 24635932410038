export default {
  footer: '© Yago S.A. {year} - Alle rechten voorbehouden.',
  helpdesk: {
    field_name: {
      audience: 'Doelgroep',
      client: 'Klant',
      description: 'Beschrijving',
      domain: 'Domein',
      link: 'Link',
      priority: 'Urgentie'
    },
    placeholders: {
      client: 'Laat leeg indien geen specifieke klant',
      description: 'Wees zo specifiek mogelijk: wat was het doel, wat is de foutmelding?',
      link: 'Kopieer en plak de URL indien relevant',
      select: 'Selecteren'
    },
    send_report: 'Rapport verzenden',
    title: 'Een bug melden',
    unavailable:
      'De IT-helpdesk wordt vernieuwd en is binnenkort weer terug!<br><br>In de tussentijd, gelieve verzoeken direct op Slack te plaatsen (met gebruik van het juiste sjabloon).'
  },
  navbar: {
    subtabs: {
      amendments: 'Aanhangsels',
      car_contract_requests: 'Autocontracten',
      reduction_campaigns: 'Kortingscampagnes',
      contracts: 'Te ondertekenen contracten',
      house_contract_requests: 'Wooncontracten',
      insurers: 'Verzekeraars',
      agent_accounts: 'Comptes Agents',
      life_procedures: 'Levensverzekeringsprocedures',
      messages: 'SMS',
      motorbike_contract_requests: 'Motorcontracten',
      pdf_procedures: 'PDF procedures',
      sepa_mandates: 'SEPA-mandaten',
      targets: 'Doelen',
      terminations: 'Opzeggingen',
      transfers: 'Overdrachten',
      unpaids: 'Wanbetalingen',
      wins: 'Wins',
      companies: 'Bedrijven'
    },
    tabs: {
      actions: 'Monitoring & Acties',
      consultation: 'Raadpleging',
      contracts: 'Leads & Contracten',
      home: 'Home',
      tools: 'Tools',
      b2b: 'B2B'
    }
  }
}
