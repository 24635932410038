import initialState from '../_helpers/initialState'
import updateList from '../_helpers/updateList'
import { SET_LOADING, SET_INSURERS, UPDATE_INSURER } from './constants'
import { insurers } from './services'

export default {
  namespaced: true,
  state: {
    ...initialState
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload
    },
    [SET_INSURERS](state, payload) {
      state.data = payload
    },
    [UPDATE_INSURER](state, payload) {
      updateList({ data: state.data, payload, idArg: 'id' })
    }
  },
  actions: {
    fetch({ state, commit }) {
      commit(SET_LOADING, true)
      insurers.index().then((response) => {
        commit(
          SET_INSURERS,
          response.data.data
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .filter((i) => !['test', 'ms-amlin', 'touring', 'vdh'].includes(i.id))
        )
        commit(SET_LOADING, false)
      })
    },
    async save({ state, commit }, payload) {
      const { data, success, message } = await insurers.update(payload)
      if (success) commit(UPDATE_INSURER, data)
      return { success, message }
    }
  }
}
