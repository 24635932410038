export default {
  title: 'Boekhouding',
  irregularities: {
    duplicate_premium_due_notice: 'Gedupliceerde kwitantie',
    missing_splitting: 'Ontbrekend fractionering',
    missing_period: 'Ontbrekende periode',
    missing_net_premium: 'Ontbrekende nettopremie',
    missing_total_premium: 'Ontbrekende totale premie',
    missing_commission: 'Ontbrekende commissie',
    mismatched_splitting_and_period: 'Ongelijkheid tussen fractionering en periode',
    overlapping_notice_periods: 'Overlapping met een andere kwitantie'
  }
}
