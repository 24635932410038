export default {
  meta: {
    loading: false,
    success: false,
    actions: {},
    error: ''
  },
  amendments: [
    {
      category: null,
      description: null,
      documents: {
        signed: null,
        unsigned: null
      },
      status: {
        label: null,
        value: null
      },
      uuid: null
    }
  ],
  availableActions: [],
  bundledPayment: null,
  chargebeeSubscriptionId: null,
  company: null,
  coupling: {
    coupled: null,
    formula: null,
    role: null
  },
  covers: [
    {
      riskObject: null,
      riskReference: null
    }
  ],
  currentlyValid: null,
  document: {
    signed: null
  },
  drivers: [],
  endDate: 0,
  greenCards: [
    {
      document: null,
      temporary: null,
      validFrom: 0,
      validUntil: 0,
      uuid: null
    }
  ],
  insurerPolicyDocumentUrl: null,
  insurerCommission: 0,
  commission: 0,
  insurerPremiumCollectionMethod: null,
  insurerSplitting: null,
  insurerTotalPremium: 0,
  grossPremium: 0,
  insurer: {
    id: null,
    slug: null
  },
  links: {
    backOffice: null
  },
  product: null,
  quittance: null,
  indexationQuittances: [],
  reference: null,
  riskObjects: [
    {
      alias: null,
      reference: null,
      details: {
        alarmInstalled: null,
        buildYear: 0,
        chassisNumber: null,
        documents: {
          registrationRequest: null,
          greyCardFront: null,
          greyCardBack: null,
          invoice: null
        },
        engineDisplacement: null,
        entryIntoCirculation: 0,
        licencePlate: null,
        manufacturer: {
          id: null,
          name: null
        },
        modelLabel: null,
        preTaxPrice: null,
        secondHand: null,
        usage: null,
        type: null,
        uuid: null
      }
    }
  ],
  operator: null,
  splitting: null,
  startDate: 0,
  status: {
    label: null,
    value: null
  },
  stoppedAt: 0,
  stoppingEncodedAt: null,
  stoppingEncodedBy: null,
  stoppingReason: {
    label: null,
    value: null
  },
  stoppingSchedule: {
    stoppingScheduled: false,
    stoppingReason: null,
    stoppingDate: null
  },
  user: {
    email: null,
    firstName: null,
    fullName: null,
    lastName: null,
    uuid: null
  },
  updatedAt: 0,
  uuid: null,
  premiumCollectionMethod: null,
  premiumDueNotices: []
}
