export default {
  agent_account_not_created: 'Agentaccount niet aangemaakt',
  account_number: 'Accountnummer',
  create_agent_account: 'Maak de agentaccount',
  deactivate: 'Deactiveren',
  description: 'Beschrijving',
  edit_agent_account: 'Bewerken agent account',
  new_agent_account: 'Nieuwe agent account',
  office: 'Kantoor',
  title: 'Agent account',
  offices: [
    {
      label: 'Marsh',
      value: 'marsh'
    },
    {
      label: 'Stroobants',
      value: 'stroobants'
    },
    {
      label: 'Yago',
      value: 'yago'
    }
  ],
  statuses: {
    active: 'active',
    inactive: 'inactief'
  }
}
