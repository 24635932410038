export default {
  activities: {
    average: 'gemiddeld: ',
    deals_to_close: 'Mijn te closen deals',
    monthly_full_package_bundles_created: 'Mijn multi-contract voorstellen van de maand',
    offers_to_send: 'Mijn te versturen voorstellen',
    total: 'Totaal: '
  },
  calls: {
    my_calls: 'Mijn calls (>30 sec.) van vandaag',
    my_on_call_time: 'Mijn in-call minuten van vandaag'
  },
  clients_called: 'Klanten gebeld',
  offers_sent: 'Voorstellen verstuurd',
  welcome: 'Welkom,',
  wins: {
    all_daily_wins: 'Alle wins van de dag',
    all_monthly_wins: 'Alle wins van de maand',
    form_title: 'Nieuwe Deal',
    goal_met: 'Gefeliciteerd team: doelstelling behaald! 🎉',
    index: {
      title: 'Mijn wins',
      total: 'TOTAAL:'
    },
    my_monthly_wins: 'Mijn maandelijkse wins',
    my_weekly_wins: 'Mijn wekelijkse wins',
    online_monthly_wins: 'Online wins van de maand',
    title: 'wins'
  }
}
