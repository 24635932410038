export default {
  agent_account_not_created: 'Compte agent non créé',
  account_number: 'Numéro du compte',
  create_agent_account: 'Créer le compte agent',
  deactivate: 'Désactiver',
  description: 'Description',
  edit_agent_account: 'Editer le compte agent',
  new_agent_account: 'Nouveau compte agent',
  office: 'Bureau',
  title: 'Compte agents',
  offices: [
    {
      label: 'Marsh',
      value: 'marsh'
    },
    {
      label: 'Stroobants',
      value: 'stroobants'
    },
    {
      label: 'Yago',
      value: 'yago'
    }
  ],
  statuses: {
    active: 'Actif',
    inactive: 'Inactif'
  }
}
