export default {
  activities: {
    average: 'moyenne : ',
    deals_to_close: 'Mes deals à closer',
    monthly_full_package_bundles_created: 'Mes OCC créées du mois',
    offers_to_send: 'Mes offres à faire',
    total: 'Total : '
  },
  calls: {
    my_calls: 'Mes calls (>30 sec.) du jour',
    my_on_call_time: 'Mes minutes on-call du jour'
  },
  clients_called: 'Clients appelés',
  offers_sent: 'Offres envoyées',
  welcome: 'Bienvenue,',
  wins: {
    all_daily_wins: 'Tous les wins du jour',
    all_monthly_wins: 'Tous les wins du mois',
    form_title: 'Nouvelle Affaire',
    goal_met: 'Bravo la team: objectif de win atteint !🎉',
    index: {
      title: 'Mes wins',
      total: 'TOTAL:'
    },
    my_monthly_wins: 'Mes wins du mois',
    my_weekly_wins: 'Mes wins de la semaine',
    online_monthly_wins: 'Online wins du mois',
    title: 'Wins'
  }
}
