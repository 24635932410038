/* eslint-disable camelcase */
import accident_records from './fr/accidentRecords'
import accounting from './fr/accounting'
import amendments from './fr/amendments'
import bug from './fr/bug'
import bundled_payment from './fr/bundledPayment'
import car_vehicle from './fr/carVehicle'
import claims from './fr/claims'
import reduction_campaigns from './fr/reductionCampaigns'
import contract_requests from './fr/contractRequests'
import contracts from './fr/contracts'
import full_package_bundle from './fr/fullPackageBundle'
import gamification from './fr/gamification'
import green_cards from './fr/greenCards'
import home from './fr/home'
import house from './fr/house'
import insurers from './fr/insurers'
import layout from './fr/layout'
import life_procedures from './fr/lifeProcedures'
import motorbike_vehicle from './fr/motorbikeVehicle'
import pdf_procedures from './fr/pdfProcedures'
import sepa_mandates from './fr/sepaMandates'
import shared from './fr/shared'
import sms from './fr/sms'
import sms_templates from './fr/smsTemplates'
import targets from './fr/targets'
import terminations from './fr/terminations'
import transfers from './fr/transfers'
import unpaids from './fr/unpaids'
import users from './fr/users'
import wins from './fr/wins'
import companies from './fr/companies'
import members from './fr/members'
import riskObjects from './fr/riskObjects'
import operators from './fr/operators'
import agentAccounts from './fr/agentAccounts'

export default {
  accident_records,
  accounting,
  amendments,
  bug,
  bundled_payment,
  car_vehicle,
  claims,
  reduction_campaigns,
  contract_requests,
  contracts,
  full_package_bundle,
  gamification,
  green_cards,
  home,
  house,
  insurers,
  layout,
  life_procedures,
  motorbike_vehicle,
  pdf_procedures,
  sepa_mandates,
  shared,
  sms,
  sms_templates,
  targets,
  terminations,
  transfers,
  unpaids,
  users,
  wins,
  companies,
  members,
  riskObjects,
  operators,
  agentAccounts
}
