import api_v2 from '@/services/api_v2'

export const insurers = {
  index() {
    return api_v2.getInsurers()
  },
  update(data) {
    return api_v2.updateInsurer(data.slug)
  }
}
