<template>
  <h2>{{ $t('shared.forms.titles.add_client') }}</h2>
  <el-form :model="user" :ref="formName" :rules="rules" label-width="140px">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item :label="$t('shared.labels.email')" prop="email">
          <el-input v-model="user.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('shared.labels.first_name')">
          <el-input v-model="user.first_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('shared.labels.mobile_phone')">
          <el-input v-model="user.mobile_phone"></el-input>
        </el-form-item>
        <el-form-item :label="$t('shared.labels.team')">
          <el-select v-model="user.team_id" :placeholder="$t('shared.placeholders.select')">
            <el-option v-for="team in teams" :key="team.team_id" :label="team.name" :value="team.team_id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="$t('shared.labels.broker')" prop="broker_id">
          <el-select v-model="user.broker_id" :placeholder="$t('shared.placeholders.select')">
            <el-option label="Yago" value="yago"></el-option>
            <el-option label="Stroobants - Tanguy Stroobants" value="stroobants_ts"></el-option>
            <el-option label="Stroobants - Quentin De Brabant" value="stroobants_qdb"></el-option>
            <el-option label="Stroobants - CR2000" value="stroobants_cr2000"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('shared.labels.last_name')">
          <el-input v-model="user.last_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('shared.labels.birthdate')">
          <el-date-picker
            v-model="user.birthdate"
            type="date"
            :placeholder="$t('shared.placeholders.select')"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('shared.labels.language')">
          <el-radio-group v-model="user.language">
            <el-radio label="fr">{{ $t('members.language_options.french') }}</el-radio>
            <el-radio label="nl">{{ $t('members.language_options.dutch') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <hr />
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col>
        <el-button @click="handleSubmit" type="primary" :loading="loading" style="margin-left: 1050px">{{
          $t('shared.actions.save')
        }}</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import api from '@/services/api_v2'
import { mapActions, mapState } from 'vuex'

export default {
  async mounted() {
    await this.fetchTeams()
  },
  data() {
    return {
      formName: 'addClientForm',
      loading: false,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        mobile_phone: null,
        birthdate: null,
        language: null,
        team_id: null,
        broker_id: 'yago'
      },
      rules: {
        email: [{ required: true, message: this.$t('shared.forms.validations.required'), trigger: 'blur' }],
        broker_id: [{ required: true, message: this.$t('shared.forms.validations.required'), trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState('operator', { operator: 'data' }),
    ...mapState('operators', ['teams'])
  },
  methods: {
    ...mapActions('operators', ['fetchTeams']),
    handleClose() {
      this.user = {
        first_name: '',
        last_name: '',
        email: '',
        mobile_phone: null,
        birthdate: null,
        language: null,
        team_id: null,
        broker_id: 'yago'
      }
      this.$emit('close')
    },
    validate() {
      if (this.user.email.trim() === '') return false
      if (this.user.broker_id.trim() === '') return false
      if (this.isEmpty(this.user.first_name) && !this.isEmpty(this.user.last_name)) return false
      if (this.isEmpty(this.user.last_name) && !this.isEmpty(this.user.first_name)) return false

      return true
    },
    isEmpty(value) {
      return value === null || value.trim() === ''
    },
    async handleSubmit() {
      if (!this.validate()) {
        this.$message({
          message: this.$t('shared.messages.encoding_problem'),
          type: 'error'
        })
        return
      }

      this.loading = true
      api
        .registerClient({ ...this.user, operator_id: this.operator.uuid })
        .then((response) => {
          let clientId = response.data.client_id
          this.$message({
            message: 'User created successfully',
            type: 'success'
          })
          setTimeout(() => {
            this.loading = false
            this.handleClose()
            this.$router.push({ name: 'users', params: { uuid: clientId } })
          }, 800)
        })
        .catch((error) => {
          this.loading = false
          this.$message({
            message: error.response.data.message,
            type: 'error'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-size: 25px;
}
</style>
